@media (min-width: 1281px) {
  .Paid{
    width: 60%;
    margin-left: 20%;
    margin-right:20%;
  }
   .textPaymentTop{
    color:green; 
    text-align:center;
    margin-top: 2%;
    font-size: 30px;
    font-weight: 700;

  }
  .textPaymentUnder{
    color: #b9b8b8;
    text-align: center;
    font-size: 17px;
  }
  /* ##Device = Desktops */
.bodyStyle{
  background-image: url(./image/bg.png);
  background-repeat: no-repeat;
  background-size: 100%;
}
  .copied{
    background-color: #efefef;
    margin-top: -13%;
    margin-bottom: 10%;
    opacity: 0;
    color: #333333;
    text-align: center;
    transform-origin: top;
    margin-left: auto;
    margin-right: auto;
    width: 39%;
    border-radius: 4px;
    animation: notify 2s ease;
    font-size: 15px;
    padding: 10px 0 10px 0;
  }
  @keyframes notify {
    0% {opacity: 0;margin:0 auto 0 auto;}
    10% {opacity: 0.9;margin:0 auto 0 auto;}
    50% {opacity: 0.9;margin:0 auto 0 auto;}
    75% {opacity: 0.2;margin:0 auto 0 auto;}
    100% {opacity: 0;margin: -10px -10px -10px -10px;}
  }

  .logoAlqolam {
    width: 10%;
    display:block;
    margin-left:auto;
    margin-right:auto;
    margin-top:10px;
    margin-bottom:-25px;
  }

  .pembayaran {
    padding:0px 7px 0px;
    color:#4a4949;
    text-align:center;
    font-size: 18px;
  }

  .noVA {
    padding:-24px 0px 0px 0px;
    text-align:center;
    font-size: 40px;
    color: green;
  }

  .caraPembayaran {
    color:#4a4949;
    text-align:center;
    font-size: 17px;
  }

  .logoBank{
    width: 11%;
    display:block;
    margin-left:auto;
    margin-right:auto;
    margin-top:0;
    margin-bottom:15px;
  }  
  .accordion {
    margin: 0 20% 0 20%;
  }
  .accordion > div > div:first-of-type {
    padding: 1em;
    background-color: #ddd;
    color: black;
    cursor: pointer;
    border-radius: 3px;
    font-size: 20px;
    font-weight: 600;
  }
  .accordion > div > div:first-of-type:hover {
    background-color: #ddd;
  }
  .accordion > div > div:last-of-type {
    background-color: #f9f9f9;
  }
  .accordion .accordion-item {
    padding-right: 1em;
    opacity: 0;
    transition: opacity 300ms ease;
    overflow: auto;
    font-size: 17px;
  }
  .accordion > div:first-child .accordion-item {
  }
  .accordion > div.open .accordion-item {
    opacity: 1;
    transition: opacity 700ms ease;
    background-color: white;
  }
  .accordion img.accordion-item {
    padding: 0;
    margin: 0;
    width: 100%;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  /* ##Device = Tablets, Ipads (portrait) */
.Paid{
  width: 60%;
    margin-left:20%;
    margin-right:20%;
}
.bodyStyle{
  background-image: url(./image/bg.png);
  background-repeat: no-repeat;
  background-size: 100%;
}
  .copied{
    background-color: #efefef;
    margin-top: -13%;
    margin-bottom: 10%;
    opacity: 0;
    color: #333333a1;
    text-align: center;
    transform-origin: top;
    margin-left: auto;
    margin-right: auto;
    width: 39%;
    border-radius: 4px;
    animation: notify 2s ease;
    font-size: 10px;
    padding: 4px 0 4px 0;
  }
  @keyframes notify {
    0% {opacity: 0;margin:0 auto 0 auto;}
    10% {opacity: 0.9;margin:0 auto 0 auto;}
    50% {opacity: 0.9;margin:0 auto 0 auto;}
    75% {opacity: 0.2;margin:0 auto 0 auto;}
    100% {opacity: 0;margin: -10px -10px -10px -10px;}
  }

  .logoAlqolam {
    width: 20%;
    display:block;
    margin-left:auto;
    margin-right:auto;
    margin-top:0px;
    margin-bottom:-25px;
  }

  .pembayaran {
    padding:0px 7px 0px;
    color:#4a4949;
    text-align:center;
    font-size: 17px;
  }
  .noVA {
    padding:-24px 0px 0px 0px;
    text-align:center;
    font-size: 25px;
    color: green;
  }
  .caraPembayaran {
    color:#4a4949;
    text-align:center;
    font-size: 17px;
  }
  .logoBank{
    width: 15%;
    display:block;
    margin-left:auto;
    margin-right:auto;
    margin-top:0;
    margin-bottom:15px;
  }  
  .accordion {
    margin: 0 10% 0 10%;
  }
  .accordion > div > div:first-of-type {
    padding: 1em;
    background-color: #ddd;
    color: black;
    cursor: pointer;
    border-radius: 3px;
    font-size: 20px;
  }
  .accordion > div > div:first-of-type:hover {
    background-color: #ddd;
  }
  .accordion > div > div:last-of-type {
    background-color: #f9f9f9;
  }
  .accordion .accordion-item {
    padding-right: 1em;
    opacity: 0;
    transition: opacity 300ms ease;
    overflow: auto;
    font-size: 17px;
  }
  .accordion > div:first-child .accordion-item {
  }
  .accordion > div.open .accordion-item {
    opacity: 1;
    transition: opacity 700ms ease;
  }
  .accordion img.accordion-item {
    padding: 0;
    margin: 0;
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
   /* ##Device = Low Resolution Tablets, Mobiles (Landscape) */
  .Paid{
    width: 60%;
    margin-left:20%;
    margin-right:20%;
  }
   .bodyStyle{
      background-image: url(./image/bg.png);
      background-repeat: no-repeat;
      background-size: 100%;
    }
   .copied{
    background-color: #efefef;
    margin-top: -13%;
    margin-bottom: 10%;
    opacity: 0;
    color: #333333a1;
    text-align: center;
    transform-origin: top;
    margin-left: auto;
    margin-right: auto;
    width: 39%;
    border-radius: 4px;
    animation: notify 2s ease;
    font-size: 10px;
    padding: 4px 0 4px 0;
  }
  @keyframes notify {
    0% {opacity: 0;margin:0 auto 0 auto;}
    10% {opacity: 0.9;margin:0 auto 0 auto;}
    50% {opacity: 0.9;margin:0 auto 0 auto;}
    75% {opacity: 0.2;margin:0 auto 0 auto;}
    100% {opacity: 0;margin: -10px -10px -10px -10px;}
  }
  .logoAlqolam {
    width: 20%;
    display:block;
    margin-left:auto;
    margin-right:auto;
    margin-top:0px;
    margin-bottom:-25px;
  }

  .pembayaran {
    padding:0px 7px 0px;
    color:#4a4949;
    text-align:center;
    font-size: 16px;
  }

  .noVA {
    padding:-24px 0px 0px 0px;
    text-align:center;
    font-size: 24px;
    color: green;
  }

  .caraPembayaran {
    color:#4a4949;
    text-align:center;
    font-size: 16px;
  }

  .logoBank{
    width: 25%;
    display:block;
    margin-left:auto;
    margin-right:auto;
    margin-top:0;
    margin-bottom:15px;
  }

  .accordion {
    margin: 0 2% 0 2%;
  }
  .accordion > div > div:first-of-type {
    padding: 1em;
    background-color: #ddd;
    color: black;
    cursor: pointer;
    border-radius: 3px;
    font-size: 20px;
  }
  .accordion > div > div:first-of-type:hover {
    background-color: #ddd;
  }
  .accordion > div > div:last-of-type {
    background-color: #f9f9f9;
  }
  .accordion .accordion-item {
    padding-right: 1em;
    opacity: 0;
    transition: opacity 300ms ease;
    overflow: auto;
    font-size: 17px;
  }
  .accordion > div:first-child .accordion-item {
  }
  .accordion > div.open .accordion-item {
    opacity: 1;
    transition: opacity 700ms ease;
  }
  .accordion img.accordion-item {
    padding: 0;
    margin: 0;
    width: 100%;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
   /* ##Device = Most of the Smartphones Mobiles (Portrait) */
  .Paid{
    width: 80%;
    margin-left:10%;
    margin-right:10%;
  }
   .bodyStyle{
      background-image: url(./image/bg.png);
      background-repeat: no-repeat;
      background-size: 100%;
    }
   .copied{
    background-color: #efefef;
    margin-top: -13%;
    margin-bottom: 10%;
    opacity: 0;
    color: #333333a1;
    text-align: center;
    transform-origin: top;
    margin-left: auto;
    margin-right: auto;
    width: 39%;
    border-radius: 4px;
    animation: notify 2s ease;
    font-size: 10px;
    padding: 4px 0 4px 0;
  }
  @keyframes notify {
    0% {opacity: 0;margin:0 auto 0 auto;}
    10% {opacity: 0.9;margin:0 auto 0 auto;}
    50% {opacity: 0.9;margin:0 auto 0 auto;}
    75% {opacity: 0.2;margin:0 auto 0 auto;}
    100% {opacity: 0;margin: -10px -10px -10px -10px;}
  }
  .logoAlqolam {
    width: 30%;
    display:block;
    margin-left:auto;
    margin-right:auto;
    margin-top:0px;
    margin-bottom:-25px;
  }

  .pembayaran {
    padding:0px 7px 0px;
    color:#4a4949;
    text-align:center;
    font-size: 16px;
  }

  .noVA {
    padding:-24px 0px 0px 0px;
    text-align:center;
    font-size: 24px;
    color: green;
  }

  .caraPembayaran {
    color:#4a4949;
    text-align:center;
    font-size: 16px;
  }

  .logoBank{
    width: 25%;
    display:block;
    margin-left:auto;
    margin-right:auto;
    margin-top:0;
    margin-bottom:15px;
  }

  .accordion {
    margin: 0 2% 0 2%;
  }
  .accordion > div > div:first-of-type {
    padding: 1em;
    background-color: #ddd;
    color: black;
    cursor: pointer;
    border-radius: 3px;
    font-size: 20px;
  }
  .accordion > div > div:first-of-type:hover {
    background-color: #ddd;
  }
  .accordion > div > div:last-of-type {
    background-color: #f9f9f9;
  }
  .accordion .accordion-item {
    padding-right: 1em;
    opacity: 0;
    transition: opacity 300ms ease;
    overflow: auto;
    font-size: 17px;
  }
  .accordion > div:first-child .accordion-item {
  }
  .accordion > div.open .accordion-item {
    opacity: 1;
    transition: opacity 700ms ease;
  }
  .accordion img.accordion-item {
    padding: 0;
    margin: 0;
    width: 100%;
  }
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .Paid{
    margin-top: 40%;
    width: 100%;
  }
  .textPaymentTop{
    color:green; 
    text-align:center;
    margin-top: 10%;
    font-size: 17px;

  }
  .textPaymentUnder{
    color: #b9b8b8;
    text-align: center;
    font-size: 13px;
  }
  /* DEVICE MOBILE */
  .bodyStyle{
    background-image: url(./image/bg.png);
    /* background-repeat: no-repeat; */
    background-size: 100%;
  }
  .copied{
    background-color: #efefef;
    margin-top: -13%;
    margin-bottom: 10%;
    opacity: 0;
    color: #333333a1;
    text-align: center;
    transform-origin: top;
    margin-left: auto;
    margin-right: auto;
    width: 39%;
    border-radius: 4px;
    animation: notify 2s ease;
    font-size: 10px;
    padding: 4px 0 4px 0;
  }
  @keyframes notify {
    0% {opacity: 0;margin:0 auto 0 auto;}
    10% {opacity: 0.9;margin:0 auto 0 auto;}
    50% {opacity: 0.9;margin:0 auto 0 auto;}
    75% {opacity: 0.2;margin:0 auto 0 auto;}
    100% {opacity: 0;margin: -10px -10px -10px -10px;}
  }
  .logoAlqolam {
    width: 40%;
    display:block;
    margin-left:auto;
    margin-right:auto;
    margin-top:0px;
    margin-bottom:-25px;
  }

  .pembayaran {
    color:#4a4949;
    text-align:center;
    font-size: 14px;
    margin-top: 2em !important;
    margin-bottom: 1em !important;
  }

  .noVA {
    padding:-30px 0px 0px 0px;
    margin: 0 !important;
    text-align:center;
    font-size: 24px;
    color: green;
  }

  .caraPembayaran {
    color:#4a4949;
    text-align:center;
    font-size: 14px;
  }

  .logoBank{
    width: 35%;
    display:block;
    margin-left:auto;
    margin-right:auto;
    margin-top:0;
    margin-bottom:15px;
  }

  .accordion {
    margin: 0 2% 0 2%;
  }
  .accordion > div > div:first-of-type {
    padding: 1em;
    background-color: #ddd;
    color: black;
    cursor: pointer;
    border-radius: 3px;
    font-size: 16px;
  }
  .accordion > div > div:first-of-type:hover {
    background-color: #ddd;
  }
  .accordion > div > div:last-of-type {
    background-color: #f9f9f9;
  }
  .accordion .accordion-item {
    padding-right: 1em;
    opacity: 0;
    transition: opacity 300ms ease;
    overflow: auto;
    font-size: 14px;
  }
  .accordion > div:first-child .accordion-item {
  }
  .accordion > div.open .accordion-item {
    opacity: 1;
    transition: opacity 700ms ease;
  }
  .accordion img.accordion-item {
    padding: 0;
    margin: 0;
    width: 100%;
  }
}


.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
